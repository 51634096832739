/**
 * User Agent Browser, OS, platform and brand detection.
 * Detects user's browser, OS, inlcuding versions. Adds correct platform class (e.g. 'js-pc') on <html>. Pushes detected values to avastGlobals object
 * @version 1.0.0
 * @author Vendula Fryzlova <vendula.fryzlova@avast.com>
 * @copyright Avast Software s.r.o &copy;2022
 */

/* eslint-disable complexity */

window.avastGlobals = window.avastGlobals || {};

(function() {
	'use strict';

	var html = document.documentElement,
		hash = window.location.hash.substring(1),
		userAgent = navigator.userAgent.toLowerCase(),
		userLanguage = navigator.language.toLowerCase(),
		platform = 'pc',
		platforms = ['android', 'ios', 'mac', 'pc'],
		browserName = 'Chrome',
		browserVersion = '',
		os = '',
		osVersion = '';

	// Check if the browser supports the Client Hints
	if (navigator.userAgentData !== undefined && navigator.userAgentData.brands.length > 0 && navigator.userAgentData.platform !== undefined) {
		// Client Hints os and platform detection
		os = navigator.userAgentData.platform.toLowerCase();

		if (/windows/.test(os)) {
			os = 'Windows';
		} else if (/linux/.test(os)) {
			os = 'Linux';
		} else if (/android/.test(os)) {
			platform = 'android';
			os = 'Android';
		} else if (/ios/.test(os)) {
			platform = 'ios';
			os = 'IOS';
		} else if (/macos/.test(os)) {
			platform = 'mac';
			os = 'Mac';
		}

		// Client Hints browser detection
		var brands = navigator.userAgentData.brands;
		for (var item in brands) {
			var browser = brands[item];

			if (/Chrome/.test(browser.brand)) {
				browserName = 'Chrome';
				browserVersion = browser.version;
			} else if (/Opera/.test(browser.brand)) {
				browserName = 'Opera';
				browserVersion = browser.version;
			} else if (/Edge/.test(browser.brand)) {
				browserName = 'EdgeChromium';
				browserVersion = browser.version;
			} else if (/Avast|AVG|CCleaner|Avira|Norton/.test(browser.brand)) {
				browserName = 'Avast';
				browserVersion = browser.version;
			}
		}
	} else {
		// User agent strings os and platform detection
		if (/windows phone/.test(userAgent)) {
			os = 'WindowsPhone';
		} else if (/windows/.test(userAgent)) {
			os = 'Windows';
		} else if (/linux/.test(userAgent) && /linux\s([^;]+)/.test(userAgent)) {
			os = 'Linux';
		} else if (/x11/.test(userAgent)) {
			os = 'Unix';
		} else if (/android/.test(userAgent)) {
			os = 'Android';
			platform = 'android';
		} else if (/iphone|ipad|ipod/.test(userAgent)) {
			os = 'IOS';
			platform = 'ios';
		} else if (/mac/.test(userAgent)) {
			os = 'Mac';
			platform = 'mac';
		}

		// User agent strings browser detection
		if (/avastium|avast|avg|ccleaner|avira|norton/.test(userAgent)) {
			browserName = 'Avast';
			browserVersion = userAgent.match(/(?:avastium\s\()(\d+(?:\.\d+)+)|\s(avast|avg|ccleaner)\/\K(\d+(?:\.\d+)+)/)[1];
		} else if (/edge|edgios/.test(userAgent)) {
			browserName = 'Edge';
			if (userAgent.match(/(?:edge|edgios)\/(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:edge|edgios)\/(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/edg/.test(userAgent)) {
			browserName = 'EdgeChromium';
			if (userAgent.match(/(?:edg)\/(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:edg)\/(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/opr|opera/.test(userAgent)) {
			browserName = 'Opera';
			if (userAgent.match(/(?:opr\/|opera(?:\s|\/)|version\/)(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:opr\/|opera(?:\s|\/)|version\/)(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/yabrowser|yowser/.test(userAgent)) {
			browserName = 'Yandex';
		} else if (/chrome|crios|crmo/.test(userAgent)) {
			browserName = 'Chrome';
			if (userAgent.match(/(?:chrome|crios|crmo)\/(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:chrome|crios|crmo)\/(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/firefox|iceweasel|fxios/.test(userAgent)) {
			browserName = 'Firefox';
			if (userAgent.match(/(?:firefox|iceweasel|fxios)\/(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:firefox|iceweasel|fxios)\/(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/safari/.test(userAgent)) {
			browserName = 'Safari';
			if (userAgent.match(/(?:version|safari)\/(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:version|safari)\/(\d+(?:\.\d+)+)/)[1];
			}
		} else if (/msie|trident/.test(userAgent)) {
			browserName = 'InternetExplorer';
			if (userAgent.match(/(?:rv:|msie\s)(\d+(?:\.\d+)+)/)) {
				browserVersion = userAgent.match(/(?:rv:|msie\s)(\d+(?:\.\d+)+)/)[1];
			}
		}
	}

	// User agent strings os version detection
	if (os === 'Windows') {
		if (/windows nt 10.0/.test(userAgent)) {
			osVersion = '10';
		} else if (/windows nt 6.3/.test(userAgent)) {
			osVersion = '8.1';
		} else if (/windows nt 6.2/.test(userAgent)) {
			osVersion = '8';
		} else if (/windows nt 6.1/.test(userAgent)) {
			osVersion = '7';
		} else if (/windows nt 6.0/.test(userAgent)) {
			osVersion = 'Vista';
		} else if (/windows nt (?:5.1|5.2)/.test(userAgent)) {
			osVersion = 'XP';
		} else if (/windows nt 5.0/.test(userAgent)) {
			osVersion = '2000';
		}
	} else if (os === 'Android' && userAgent.match(/android (\d+(?:\.\d+)*)/)) {
		osVersion = userAgent.match(/android (\d+(?:\.\d+)*)/)[1];
	} else if (os === 'IOS' && userAgent.match(/cpu\s(?:iphone\s)?os\s(\d+(?:_\d+)+)/)) {
		osVersion = userAgent.match(/cpu\s(?:iphone\s)?os\s(\d+(?:_\d+)+)/)[1];
	} else if (os === 'Mac' && userAgent.match(/mac\sos\sx\s(\d+(?:_|.?\d+)+)/)) {
		osVersion = userAgent.match(/mac\sos\sx\s(\d+(?:_|.?\d+)+)/)[1];
	} else if (os === 'Linux' && userAgent.match(/linux\s(\w\d+_?\d+)?/)) {
		osVersion = userAgent.match(/linux\s(\w\d+_?\d+)?/)[1];
	} else if (os === 'Unix' && userAgent.match(/x11;\s(?:u;)?([^;]+)/)) {
		osVersion = userAgent.match(/x11;\s(?:u;)?([^;]+)/)[1];
	}

	// Set platform in URL after #
	if (!window.location.hash) {
		window.location.hash = platform;
	}

	// Push data to avastGlobals
	window.avastGlobals.detect = {
		browser: {
			isChrome: false,
			isSafari: false,
			isFirefox: false,
			isInternetExplorer: false,
			isAvast: false,
			isEdge: false,
			isEdgeChromium: false,
			isOpera: false,
			platform: platform,
			userAgent: userAgent,
			userLanguage: userLanguage,
			name: browserName,
			version: browserVersion
		},
		os: {
			isWindows: false,
			isMac: false,
			isAndroid: false,
			isIOS: false,
			isLinux: false,
			isUnix: false,
			isWindowsPhone: false,
			name: os,
			version: osVersion
		}
	};

	// Based on the detected browser, set 'true' as a value for browser
	if (browserName !== '') {
		for (var detectedBrowser in window.avastGlobals.detect.browser) {
			var isBrowser = detectedBrowser.substring(2);

			if (isBrowser.match(window.avastGlobals.detect.browser.name)) {
				window.avastGlobals.detect.browser[detectedBrowser] = true;
				break;
			}
		}
	}

	// Based on the detected OS, set 'true' as a value for os
	if (os !== '') {
		for (var detectedOs in window.avastGlobals.detect.os) {
			var isOs = detectedOs.substring(2);

			if (isOs.match(window.avastGlobals.detect.os.name)) {
				if (!isOs.match('WindowsPhone')) {
					window.avastGlobals.detect.os[detectedOs] = true;
					break;
				} else if (isOs.match('WindowsPhone') && window.avastGlobals.detect.os.name.match('WindowsPhone')) {
					window.avastGlobals.detect.os.isWindowsPhone = true;
					window.avastGlobals.detect.os.isWindows = false;
					break;
				}
			}
		}
	}

	// Reset platform class on <html>
	function resetPlatform() {
		html.classList.remove('js-pc');
		html.classList.remove('js-android');
		html.classList.remove('js-ios');
		html.classList.remove('js-mac');
	}

	// Add platform classes to <html>
	function setPlatform() {
		resetPlatform();
		html.classList.add('js-root-' + platform);
		html.classList.add('js-' + platform);
	}

	setPlatform();

	// Add browser class on <html>
	function setBrowser() {
		var browserClass = ''
		if (browserName === 'EdgeChromium') {
			browserClass = 'js-edgchrom'
		} else if (browserName === 'InternetExplorer') {
			browserClass = 'js-ie'
		} else {
			browserClass = 'js-' + browserName.toLowerCase()
		}
		html.classList.add(browserClass)
	}

	setBrowser()

	function hashValueToHtml() {
		hash = window.location.hash.substring(1);
		if (platforms.includes(hash)) {
			resetPlatform();
			html.classList.add('js-' + hash);
		}
	}

	// If content after # in URL changes and matches item in array 'platforms', remove class 'js-platform' from <html> and add new one accordingly
	window.addEventListener('hashchange', hashValueToHtml);

	hashValueToHtml();

	// // Output check
	// console.log('Script detected:' +
	// 		'\n\nbrowser: ' +
	// 		avastGlobals.detect.browser.name +
	// 		'\nbrowser version: ' +
	// 		avastGlobals.detect.browser.version +
	// 		'\nos: ' +
	// 		avastGlobals.detect.os.name +
	// 		'\nos version: ' +
	// 		avastGlobals.detect.os.version +
	// 		'\nplatform: ' +
	// 		avastGlobals.detect.browser.platform);
})();
